import React from "react"

// Libraries
import styled from "styled-components"

// // Utils
// import breakpoints from "utils/breakpoints"
import { colors } from "utils/variables"

// Components
import Container from "components/container/"
// import { StaticImage } from "gatsby-plugin-image"
import Notfound from "assets/images/404/icon-background.inline.svg"
import breakpoints from "utils/breakpoints"

const StyledIssues = styled.section`
  background: ${colors.silver__pale};
  position: relative;
  padding: 0 0 60px 0;

  ${breakpoints.medium`
    padding: 128px 0;
  `}

  a {
    text-decoration: underline;
  }

  p {
    max-width: 196px;
    width: 100%;
  }

  .background {
    display: none;

    ${breakpoints.small`
      display: block;
      max-width: 500px;
      width: 100%;
      height: auto;
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0;
    `}

    ${breakpoints.medium`
      max-width: 800px;
    `}

    ${breakpoints.large`
      max-width: 1046px;
      padding: 0 15px;
    `}
  }
`

const Issues = () => (
  <StyledIssues>
    <Container>
      <div className="row">
        <div className="col-12 col-sm-3" />
        <Notfound className="background" />
      </div>
    </Container>
  </StyledIssues>
)

export default Issues
