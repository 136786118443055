import React from "react"

// Libraries
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"

// Sections
import Hero from "sections/404/hero"
import Issues from "sections/404/issues"

const NotFound = () => (
  <Layout showNewsletter={false}>
    <SEO title="Not found" description="404 - Not found" />
    <Hero />
    <Issues />
  </Layout>
)

export default NotFound
