import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoints from "utils/breakpoints"
import { colors } from "utils/variables"

// Components
import Container from "components/container/"
import Button from "components/button"

const StyledHero = styled.section`
  background: ${colors.silver__pale};
  padding: 80px 0;

  ${breakpoints.medium`
    padding: 110px 0 0 0;
  `}

  .text {
    max-width: 422px;
    width: 100%;
  }
`

const Hero = () => (
  <StyledHero>
    <Container>
      <h1 className="h1 maxi mb-4">
        Oops! I think we
        <br />
        lost you.
      </h1>
      {/* <h2 className="text--normal h2 maxi font-weight--700 mt-4 mb-4">
        Oops! I think we lost you.
      </h2> */}
      <Button type="link" href="/" className="mb-4">
        Back to home
      </Button>
    </Container>
  </StyledHero>
)

export default Hero
